import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import AnimationContainer from 'components/animation-container'
import ContactCreativeForm from './parts/ContactCreativeForm.js'

class ContactCreative extends React.Component {


    render() {

        const Section = styled.section`
            position: relative;
            overflow: hidden;
            // background-color: #000;
            padding: 100px 0;
        `

        const FormRow = styled(Row)`
           background-color: #111;
        `

        const ContactCol = styled(Col)`
            min-height: 600px;
            max-height: 600px;
            padding: 0;
            display: flex;
            align-items: center;
        `

        const Map = styled.iframe`
            border: none;
            height: 100%;
            width: 100%;
        `

        const IconRow = styled(Row)`
            margin-top: 150px;
        `

        const IconCol = styled(Col)`
            @media (max-width: 500px) {
              margin-bottom: 140px;
            }
        `

        const IconContainer = styled.div`
            width: 150px;
            height: 150px;
            margin: auto;
            padding: 50px;
            text-align: center;
            position: relative;
            bottom: 75px;
            background-color: #24e5af;
            border-radius: 150px;
            transition: .5s;
        `
        
        const InfoPart = styled.div`
            min-height: 250px;
            background-color: #111;
            border: 2px solid #444;
            &:hover ${IconContainer} {
              background-color: #24e5af;
            }
        `
        const Icon = styled.img`
            height: 50px;
            width: 50px;            
            object-fit: contain;
        `

        const InfoTitle = styled.h4`
            font-size: 35px;
            color: #fff;
            font-family: Teko;
            text-align: center;
        `

        const Info = styled.div`
            position: relative;
            bottom: 30px;
        `

        const InfoLinkContainer = styled.div`
            text-align: center;
        `

        const InfoLink = styled.a`
            color: #24e5af;
            transition: .5s;
            &:hover {
              color: #fff;
              text-decoration: none;
            }
        `

        return(
            <Section id="kontakt">
                <Container>
                  {/* <AnimationContainer animation="fadeIn">
                    <FormRow>
                      <ContactCol md={6}>
                          <ContactCreativeForm />
                      </ContactCol>
                      <ContactCol md={6}>
                        <Map  style={{filter:'invert(90%)'}}
                          title="map"
                          src="https://maps.google.com/maps?width=500&amp;height=500&amp;hl=en&amp;q=Nybaneg%C3%A5rdsgade%2047+(App%20Libitum)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed"/>
                      </ContactCol>
                    </FormRow>
                  </AnimationContainer> */}
                  <IconRow>
                      <IconCol md={4}>
                        <AnimationContainer animation="fadeIn" delay={100}>
                          <InfoPart>
                            <IconContainer>
                                <Icon src={this.props.emailIcon.childImageSharp.fluid.src} alt="email" />
                            </IconContainer>
                            <Info>
                              <InfoTitle>
                                E-mail
                              </InfoTitle>
                              <InfoLinkContainer>
                                <InfoLink href="mailto:info@app-libitum.dk">
                                  info@app-libitum.dk
                                </InfoLink>
                              </InfoLinkContainer>
                            </Info>
                          </InfoPart>
                        </AnimationContainer>
                      </IconCol>
                      <IconCol md={4}>
                        <AnimationContainer animation="fadeIn" delay={100}>
                          <InfoPart>
                            <IconContainer>
                              <Icon src={this.props.phoneIcon.childImageSharp.fluid.src} alt="phone" />
                            </IconContainer>
                            <Info>
                              <InfoTitle>
                                Telefonnummer
                              </InfoTitle>
                              <InfoLinkContainer>
                                <InfoLink href="tel:+4542700215">
                                  42700215
                                </InfoLink>
                              </InfoLinkContainer>
                            </Info>
                          </InfoPart>
                        </AnimationContainer>
                      </IconCol>
                      <IconCol md={4}>
                        <AnimationContainer animation="fadeIn" delay={100}>
                          <InfoPart>
                            <IconContainer>
                              <Icon src={this.props.mapIcon.childImageSharp.fluid.src} alt="address" />
                            </IconContainer>
                            <Info>
                              <InfoTitle>
                                Adresse
                              </InfoTitle>
                              <InfoLinkContainer>
                                <InfoLink target="_blank" href="https://www.google.dk/maps/place/App+Libitum+I%2FS/@56.150978,10.2040307,17z/data=!3m1!4b1!4m5!3m4!1s0x464c3f5fd736d84b:0x8307f56b97ebfb5c!8m2!3d56.150978!4d10.2062194">
                                  <p>Ny Banegårdsgade 47, 8000 Aarhus</p>
                                </InfoLink>
                              </InfoLinkContainer>
                            </Info>
                          </InfoPart>
                        </AnimationContainer>
                      </IconCol>
                  </IconRow>
                </Container>
            </Section>
        )
    }

}

export default props => (
  <StaticQuery
    query={graphql`
    query {
      emailIcon: file(relativePath: {eq: "icons/iconemail.png"}) {
        childImageSharp {
          fluid(maxWidth: 100) {
            src
          }
        }
      }
      mapIcon: file(relativePath: {eq: "icons/iconaddress.png"}) {
        childImageSharp {
          fluid(maxWidth: 100) {
            src
          }
        }
      }
      phoneIcon: file(relativePath: {eq: "icons/iconphone.png"}) {
        childImageSharp {
          fluid(maxWidth: 100) {
            src
          }
        }
      }
    }
    `}
    render={({ 
      emailIcon, 
      mapIcon, 
      phoneIcon}) => <ContactCreative  
      emailIcon={emailIcon} 
      mapIcon={mapIcon} 
      phoneIcon={phoneIcon}
      {...props} />}
  />
)